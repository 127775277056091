import React from 'react';
import PaymentPoints from '../PaymentPoints/PaymentPoints';

const PointsPeru = () => {
  return (
    <div className="landing-page">
      <PaymentPoints countryCode="PE" countryName="Perú" />
    </div>
  );
};

export default PointsPeru;
