import React from 'react';
import { graphql, PageProps } from 'gatsby';
import PointsPeru from '../../components/Landing/PointsPeru';
import SEO from '../../components/SEO';

const PAGE_TITLE = 'Puntos de retiro en efectivo Perú';
const PAGE_DESCRIPTION =
  'Envía dinero a Perú con CurrencyBird y retira efectivo al instante en más de 7.000 puntos. Rápido y económico con tarifas transparentes.';

const PointsPeruPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} />
      <PointsPeru />
    </>
  );
};

export default PointsPeruPage;

export const pageQuery = graphql`
  query PointsPeruPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
